import "./styles.scss"

import React from "react"

const BanerImage = ({ image }) => {
  return (
    <section className="standard-baner-image">
      <div className="container-fluid">
        <img src={image} alt="" />
      </div>
    </section>
  )
}

export default BanerImage
