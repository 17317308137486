import "./styles.scss"

import React from "react"

const data = [
  {
    icon: require("assets/images/standard/standard-icon-2.svg").default,
    header: "Indywidualny, unikalny projekt zieleni w każdej inwestycji",
  },
  {
    icon: require("assets/images/standard/standard-icon-1.svg").default,
    header: "Przestronne tarasy w każdym mieszkaniu",
  },
  {
    icon: require("assets/images/standard/standard-icon-7.svg").default,
    header: "Muzyka w holu",
  },
  {
    icon: require("assets/images/standard/standard-icon-8.svg").default,
    header: "Wyjątkowy zapach w holu",
  },
  {
    icon: require("assets/images/standard/standard-icon-5.svg").default,
    header: "Doprecyzowana w każdym detalu architektura",
  },
  {
    icon: require("assets/icons/convenients/naprawa-rowerow.svg").default,
    header: "Stacja rowerowa ze strefą napraw, stojakami i myjnią",
  },
  {
    icon: require("assets/icons/convenients/czesci-wspolne-premium.svg")
      .default,
    header: "Oryginalny projekt premium części wspólnych",
  },
  {
    icon: require("assets/icons/convenients/panoramiczne-okna.svg").default,
    header: "Panoramiczne okna",
  },
]

const Icons = () => {
  return (
    <section className="standard-icons">
      <div className="container-fluid">
        <h3>
          Fundamenty Standardu <br />
          Trust Investment
        </h3>
        <div className="row">
          {data?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-6" key={index}>
              <div className="standard-icons__item">
                <div>
                  <img src={item?.icon} alt="" />
                </div>
                <h4 dangerouslySetInnerHTML={{ __html: item?.header }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
