import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import ArrowTile from "components/Icons/arrow"

const data = [
  {
    image: require("assets/images/standard/standard-tile-image-1.jpg").default,
    header: "Pomagamy uzyskać finansowanie",
    url: "/finansowanie/",
  },
  {
    image: require("assets/images/standard/standard-tile-image-2.jpg").default,
    header: "Pomagamy dostosować mieszkanie",
    url: "/zmiany-lokatorskie/",
  },
  {
    image: require("assets/images/standard/standard-tile-image-3.jpg").default,
    header: "Pomagamy wykończyć pod klucz",
    url: "/pakiety-wykonczeniowe/",
  },
  // {
  //   image: require("assets/images/standard/standard-tile-image-4.jpg").default,
  //   header: "Pomagamy zaoszczędzić na wyposażeniu",
  //   url: "/",
  // },
  {
    image: require("assets/images/standard/standard-tile-image-5.jpg").default,
    header: "Pomagamy zarabiać na wynajmie",
    url: "/zarzadzanie-najmem-krotko-dlugoterminowym/",
  },
  {
    image: require("assets/images/standard/standard-tile-image-6.jpg").default,
    header: `Program poleceń "Mieszkaj<br/> z przyjaciółmi"`,
    url: "/mieszkaj-z-przyjaciolmi/",
  },
]

const Tiles = () => {
  return (
    <section className="standard-tiles">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3>
              Dbałość o doświadczenia.
              <br /> W Standardzie.
            </h3>
          </div>
          <div className="col-md-5">
            <p>
              Naszym klientom gwarantujemy nie tylko jakościowe mieszkania i
              apartamenty, ale również pełne wsparcie na każdym etapie
              realizowania marzenia o swoim M.
            </p>
          </div>
        </div>

        <div className="row">
          {data?.map((item, index) => (
            <div className="col-md-4 col-6" key={index}>
              <Link to={item?.url} className="standard-tiles__item">
                <div
                  className="standard-tiles__item-image"
                  style={{ backgroundImage: `url('${item?.image}')` }}
                />
                <h4 dangerouslySetInnerHTML={{ __html: item?.header }}></h4>
                <span>
                  Dowiedz się więcej <ArrowTile />
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Tiles
