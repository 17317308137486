import React from "react"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Content, Grid } from "page_components/subpages"
import { Icons, BanerImage, Tiles } from "page_components/standard"

const Standard = ({ location }) => {
  const title = "Standard Trust Investment"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header={title}
        description="Standard to norma określająca podstawowe wymagania. Standard Trust Investment to coś znacznie więcej."
        background={
          require("assets/images/standard/standard-hero-background.jpg").default
        }
      />
      <Content
        header="Standard Trust Investment"
        description="Jako doświadczony deweloper doskonale zdajemy sobie sprawę z faktu, że to suma wielu drobnych elementów buduje całościowe doświadczenie, dlatego do projektowanych przez nas inwestycji, każdorazowo podchodzimy jak do unikalnego, dedykowanego projektu w którym dbamy o każdy, nawet najmniejszy szczegół. Na przestrzeni lat, wszystkie te elementy stały się częścią Standardu Trust Investment."
        image={
          require("assets/images/standard/standard-content-image.jpg").default
        }
      />
      <Icons />
      <Grid
        startReverse
        data={[
          {
            header: "Szacunek dla społeczności lokalnej. W Standardzie.",
            description:
              "Każda nowa inwestycja wiąże się z ingerencją w żywą tkankę. Tkankę w rozumieniu przestrzeni, czasem o ukształtowanym już charakterze, czasem taką, którą z poczuciem odpowiedzialności kształtować będzie nasz projekt. Co ważniejsze, w przestrzeni tej kluczowi są ludzie, dlatego planując i projektując każdą nową inwestycję, z szacunkiem i zrozumieniem podchodzimy do głosów lokalnych społeczności, aby nasze przedsięwzięcia były dla nich rozwinięciem, pozytywnym krokiem dla miejsca, z którym się identyfikują.",
            image: require("assets/images/standard/standard-grid-image-1.jpg")
              .default,
          },
        ]}
      />
      <BanerImage
        image={
          require("assets/images/standard/standard-baner-image.jpg").default
        }
      />
      <Grid
        startReverse
        data={[
          {
            header: "PRZESTRONNE BALKONY",
            description:
              "Wiemy, jak ważne jest poczucie przestrzeni. Projektując mieszkania, zawsze dbamy o to, aby powierzchnie apartamentów były powiększone dzięki naprawdę przestronnym tarasom. Otworzy to naturalną możliwość korzystania z przestrzeni zewnętrznej na co dzień.",
            image: require("assets/images/standard/standard-grid-image-2.jpg")
              .default,
          },
          {
            header: "UNIKATOWA ZIELEŃ",
            description:
              "Poczucie bliskości z naturą jest bezcenne, zwłaszcza, gdy mieszkasz w centrum miasta. W każdej naszej inwestycji zieleń jest indywidualnie projektowana przez profesjonalne biuro architektury krajobrazu.",
            image: require("assets/images/standard/standard-grid-image-3.jpg")
              .default,
          },
          {
            header: "REPREZENTACYJNA PRZESTRZEŃ",
            description:
              "W swoim domu jesteś zawsze wyczekiwanym gościem. Projektowane w naszych inwestycjach przestrzenie i części wspólne zapraszają do swego wnętrza. Już od wejścia, w holu, usłyszysz delikatne dźwięki kojącej muzyki i poczujesz relaksujący aromat wyjątkowej nuty zapachowej.",
            image: require("assets/images/standard/standard-grid-image-4.jpg")
              .default,
          },
        ]}
      />
      <Tiles />
    </Layout>
  )
}

export default Standard
